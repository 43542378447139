import { getRangeValue } from "tsparticles-engine";
export class StarDrawer {
    draw(context, particle, radius) {
        const sides = particle.sides, inset = particle.starInset ?? 2;
        context.moveTo(0, 0 - radius);
        for (let i = 0; i < sides; i++) {
            context.rotate(Math.PI / sides);
            context.lineTo(0, 0 - radius * inset);
            context.rotate(Math.PI / sides);
            context.lineTo(0, 0 - radius);
        }
    }
    getSidesCount(particle) {
        const star = particle.shapeData;
        return Math.round(getRangeValue(star?.sides ?? star?.nb_sides ?? 5));
    }
    particleInit(container, particle) {
        const star = particle.shapeData, inset = getRangeValue(star?.inset ?? 2);
        particle.starInset = inset;
    }
}
